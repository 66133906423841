// components/Menu.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../AuthContext';
import logo from '../assets/logo.svg';

const Menu: React.FC = () => {
    const { profile, isLoggedIn, handleLogin, handleLogout } = useAuth();
    const [showSubmenu, setShowSubmenu] = useState(false);
    const submenuRef = useRef<HTMLDivElement>(null);

    const toggleSubmenu = () => {
        setShowSubmenu(prevShowSubmenu => !prevShowSubmenu);
    };

    const closeSubmenu = () => {
        setShowSubmenu(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (submenuRef.current && !submenuRef.current.contains(event.target as Node)) {
            closeSubmenu();
        }
    };

    useEffect(() => {
        if (showSubmenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSubmenu]);

    const handleGoogleLogin = (response: any) => {
        closeSubmenu();
        handleLogin(response.credential);
    };

    const googleLogoutButton = (
        <div ref={submenuRef} className="dropdown">
            <button className="btn btn-light dropdown-toggle" type="button" onClick={toggleSubmenu}>
                {profile && <img src={profile.picture} alt={profile.name} style={{ width: '30px', height: '30px', borderRadius: '15px', marginRight: '10px' }} />}
                {profile && profile.name}
            </button>
            <div className={`dropdown-menu${showSubmenu ? ' show' : ''}`}>
                <button className="dropdown-item" onClick={handleLogout}>Logout</button>
            </div>
        </div>
    );

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand as={NavLink} to="/home">
                    <img
                        src={logo}
                        alt="App Logo"
                        style={{ width: '40px', height: '40px', marginRight: '10px' }} // Adjust size and margin as needed
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/home">Home</Nav.Link>
                        <Nav.Link as={NavLink} to="/faq">FAQ</Nav.Link>
                    </Nav>
                    <div className="d-flex align-items-center">
                        {isLoggedIn ? googleLogoutButton : (
                            <GoogleLogin
                                onSuccess={handleGoogleLogin}
                                onError={() => console.log('Login Failed')}
                            />
                        )}
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Menu;
