import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import StudentView from './StudentView';
import TeacherView from './TeacherView';
import ParentView from './ParentView';


const Home: React.FC = () => {
    const { isLoggedIn, profile } = useAuth();
    const [data, setData] = useState<any>(null); 
    const [dataError, setDataError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/protected`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (response.ok) {
                    const result = await response.json();
                    setData(result);
                } else {
                    setDataError('Failed to fetch data.');
                }
            } catch (err) {
                setDataError('Error fetching data.');
            } finally {
                setLoading(false);
            }
        };

        if (isLoggedIn) {
            fetchData();
        } else {
            setData(null);
            setDataError(null);
            setLoading(false);
        }
    }, [isLoggedIn]);

    if (loading) {
        return <p>Loading...</p>;
    }

    const email = profile?.email || '';
    const isStudent = email.endsWith('@student.gymkk.cz');
    const isTeacher = email.endsWith('@gymkk.cz');
    const isParent = !isStudent && !isTeacher; // Assuming a parent is anyone not a student or teacher

    return (
        <div className="home-container">
            {dataError ? (
                <p className="error-text">{dataError}</p>
            ) : (
                <div className="content-container">
                    {data ? (
                        <>
                            {isStudent && <StudentView data={data} />}
                            {isTeacher && <TeacherView data={data} />}
                            {isParent && <ParentView data={data} />}
                            {!isStudent && !isTeacher && !isParent && <p className="unauthorized-text">Unauthorized access.</p>}
                        </>
                    ) : (
                        <div className="landing-message">
                            <h2>Vítejte v aplikaci Gymnázia Kudykampus</h2>
                            <p>Přihlaste se v pravém horním rohu do svého školního účtu.</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Home;
