// utils.ts

export const encodeStudentName = (name: string): string => {
    // Simple reversible encoding function (Caesar cipher by shifting characters)
    const shift = 5;
    return Array.from(name).map(char => String.fromCharCode(char.charCodeAt(0) + shift)).join('');
};

export const decodeStudentName = (encoded: string): string => {
    const shift = 5;
    return Array.from(encoded).map(char => String.fromCharCode(char.charCodeAt(0) - shift)).join('');
};
