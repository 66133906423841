import React from 'react';
import './FAQ.css'; // Import the CSS file

const FAQ: React.FC = () => {
    return (
        <div className="faq-container">
            <h1>Mohlo by vás zajímat</h1>
            
            <div className="faq-item">
                <h2 className="faq-question">Jak počítáme procento splněné práce?</h2>
                <p className="faq-answer">
                <p>Procento splněné práce počítáme na základě zadaných úkolů v kurzu, včetně povinných a úspěšně splněných bonusových úkolů.</p>

                <ol>
                    <li><strong>Zadané práce:</strong> Nejprve vezmeme všechny úkoly, které jsou povinné, a k nim přidáme ty bonusové úkoly, které jsou úspěšně splněné. Nesplnění bonusového úkolu tedy procento negativně neovlivní.</li>
                    <li><strong>Splněné práce:</strong> Ze zadaných prací vezmeme pouze ty, které vyučující potvrdil jako splněné, tj. došlo k naplnění kritérií minimální požadované kvality.</li>
                    <li><strong>Ohodnocené práce:</strong> Ze zadaných prací uvážíme ty, které nebyly označeny jako omluvené a které už vyučující ohodnotil. Pokud je některá práce ještě neodevzdaná ale je už po termínu odevzdání, zahrneme ji k hodnocené práci také, a to jako by byla hodnocená jako nesplněná (chceme tak nastínit situaci, jak by procento odevzdané práce vypadalo, když by k odevzdání nakonec nedošlo vůbec). Takovou práci lze typicky odevzdat (nebo se pokusit o opravu) až týden po skončení studijního plánu, do kterého náleží.</li>
                </ol>

                <p>Procento splnění vychází z poměru splněné práce k celkovému počtu ohodnocené práce.</p>
                </p>
            </div>

            <div className="faq-item">
                <h2 className="faq-question">Jak počítáme procento včas splněné práce?</h2>
                <p className="faq-answer">
                Výpočet je podobný tomu, který je popsán výše. Splněná práce je zde navíc považována za splněnou pouze v případě, že k jejímu splění došlo před termínem odevzdání.
                </p>
            </div>

            <div className="faq-item">
                <h2 className="faq-question">Jakým e-mailem se mám přihlásit?</h2>
                <p className="faq-answer">
                    Přihlášení je možné pouze mailem, který dovolí přihlášení skrze Google. Studenti a učitelé se přihlašují školním e-mailem.
                    Rodiče vyplní svou e-mailovou adresu do dotazníku, který škola zasala mailem, spolu se jménem studenta, ke kterému si přejí být propojeni. Pokud jste dotazník nedostali nebo máte jiný problém, obraťte se na e-mail <a href= "mailto: filip.peska@gymkk.cz">filip.peska@gymkk.cz</a>.
                    Jakmile dojde k úspěšnému propojením vaší adresy se studentským účtem, data v aplikaci uvidíte.
                </p>
            </div>

            <div className="faq-item">
                <h2 className="faq-question">Jak poznám, že můj e-mail dovoluje přihlášení skrze Google?</h2>
                <p className="faq-answer">
                    Zkuste se přihlásit do svého mailu v pravém horním rohu. Pokud se do aplikace dostanete, buď uvidíte přímo data svých dětí, pokud už k propojení došlo, případně hlášku "Failed to fetch data", pokud propojeni nejste.
                    Pokud se vám přihlásit nepodaří, vaše e-mailová adresa pravděpodobně s naším webem kompatibilní není. V takovém případě je nejjednodušší založit si <a href="https://support.google.com/mail/answer/56256?hl=cs">Gmail</a> a vaši novou adresu nám nahlásit. Pro komunikaci s vámi budeme nadále používat pouze ty adresy, které jste nám zaslali při sběru dat do EduPage, případně nově vzniklá e-mailová adresa slouží pouze pro přihlašování do aplikace.
                </p>
            </div>
        </div>
    );
};

export default FAQ;
