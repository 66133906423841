import React from 'react';
import './CourseCard.css';

interface CourseCardProps {
  courseTitle: string;
  section: string;
  onClick: () => void; // Click handler to expand/collapse the course
}

const CourseCard: React.FC<CourseCardProps> = ({ courseTitle, section, onClick }) => {
  return (
    <div className="course-card" onClick={onClick}>
      <div className="course-card-title">{courseTitle}</div>
      <div className="course-card-count">{section}</div>
    </div>
  );
};

export default CourseCard;
