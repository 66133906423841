import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './StudentView.css';
import StudentCourseCard from './StudentCourseCard';
import StudentCourseworkTable from './StudentCourseworkTable';
import { encodeStudentName, decodeStudentName } from './utils';

interface CourseWork {
  courseId: string;
  courseTitle: string;
  courseWorkId: string;
  courseWorkTitle: string;
  grade: string | null;
  gradeCategory: string | null;
  link: string;
  status: string;
  deadline: string;
  teacherName: string;
  optional: boolean;
}

interface CourseTableProps {
  data: CourseWork[];
}

const StudentView: React.FC<CourseTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [courses, setCourses] = useState<{ [key: string]: CourseWork[] }>({});
  const [studentContext, setStudentContext] = useState<string | null>(null);

  // Parse URL to get the current selected course ID and student context
  const searchParams = new URLSearchParams(location.search);
  const selectedCourseId = searchParams.get('courseId');
  const encodedStudentName = searchParams.get('student');

  useEffect(() => {
    if (encodedStudentName) {
      const decodedName = decodeStudentName(encodedStudentName);
      setStudentContext(decodedName); // Set student context if available in URL
    }
  }, [encodedStudentName]);

  // Aggregate data by courseId
  useEffect(() => {
    const aggregatedCourses = data.reduce<{ [key: string]: CourseWork[] }>((acc, curr) => {
      if (!acc[curr.courseId]) {
        acc[curr.courseId] = [];
      }
      acc[curr.courseId].push(curr);
      return acc;
    }, {});

    // Sort courses by deadline
    Object.keys(aggregatedCourses).forEach(courseId => {
      aggregatedCourses[courseId].sort((a, b) => new Date(b.deadline).getTime() - new Date(a.deadline).getTime());
    });

    setCourses(aggregatedCourses);
  }, [data]);

  // Sort course IDs alphabetically
  const sortedCourseIds = Object.keys(courses).sort((a, b) =>
    courses[a][0].courseTitle.localeCompare(courses[b][0].courseTitle)
  );

  const handleCardClick = (courseId: string) => {
    const newUrl = studentContext ? `?student=${encodeStudentName(studentContext)}&courseId=${courseId}` : `?courseId=${courseId}`;
    navigate(newUrl);
  };

  const handleBreadcrumbClick = () => {
    const newUrl = studentContext ? `?student=${encodeStudentName(studentContext)}` : '';
    navigate(newUrl);
  };

  const isAfterDeadline = (datetimeString: string | null): boolean => {
    if (!datetimeString) return false;
    
    const deadlineDate = new Date(datetimeString);
    const currentDate = new Date();
  
    // Check if the parsed date is valid
    if (isNaN(deadlineDate.getTime())) {
      return false;
    }
  
    // Compare current date with the deadline
    return currentDate > deadlineDate;
  };

  const calculateSubmissionPercentage = (courseWorks: CourseWork[]): number => {
    // mandatory and passed bonus assignments
    const mandatoryCourseWorks = courseWorks.filter((courseWork) => courseWork.optional === false || (courseWork.optional === true && courseWork.grade !== null && parseInt(courseWork.grade) >= 2));
    const passedCourseWorks = mandatoryCourseWorks.filter((courseWork) => courseWork.grade !== null && parseInt(courseWork.grade) >= 2 && courseWork.status !== 'EXCUSED'); // 1 as that means it was submitted
    const notExcusedCourseWorks = mandatoryCourseWorks.filter((courseWork) => (courseWork.grade !== null || ((courseWork.status == "NOT_TURNED_IN" || courseWork.status == "FAILED") && isAfterDeadline(courseWork.deadline) )) && courseWork.status !== 'EXCUSED');
    const submissionPercentage = notExcusedCourseWorks.length > 0 ? (passedCourseWorks.length / notExcusedCourseWorks.length) * 100 : 100;
    return submissionPercentage;
  };

  const calculateSubmissionPercentageInTime = (courseWorks: CourseWork[]): number => {
    const mandatoryCourseWorks = courseWorks.filter((courseWork) => courseWork.optional === false || (courseWork.optional === true && courseWork.grade !== null && parseInt(courseWork.grade) >= 2));
    const gradedCourseWorksInTime = mandatoryCourseWorks.filter((courseWork) => courseWork.grade !== null && parseInt(courseWork.grade) >= 2 && courseWork.status == "TURNED_IN");
    const notExcusedCourseWorks = mandatoryCourseWorks.filter((courseWork) => (courseWork.grade !== null || ((courseWork.status == "NOT_TURNED_IN" || courseWork.status == "FAILED") && isAfterDeadline(courseWork.deadline) )) && courseWork.status !== 'EXCUSED');
    const percentage = notExcusedCourseWorks.length > 0 ? (gradedCourseWorksInTime.length / notExcusedCourseWorks.length) * 100 : 100;
    return percentage;
  };

  const calculateAverageGrade = (courseWorks: CourseWork[]): number => {
    const gradedCourseWorks = courseWorks.filter(
      (courseWork) => courseWork.grade !== null && courseWork.status !== 'EXCUSED'
    );
  
    const totalGrades = gradedCourseWorks.reduce((sum, courseWork) => sum + (Number(courseWork.grade) || 0), 0);
    const average = gradedCourseWorks.length > 0 ? totalGrades / gradedCourseWorks.length : 0;
  
    return average;
  };

  // indicator based on submitting and average grade
  // TODO: how to calculate average grade
  const determineStatusClass = (percentage: number, averageGrade: number): string => {
    return percentage >= 90 ? 'excellent' : 'bad'; // && averageGrade > 1.5 ? 'excellent' : 'bad';
  };

  // Ensure selected course exists before rendering
  const selectedCourse = selectedCourseId ? courses[selectedCourseId] : null;

  return (
    <div className="student-view-container">
      <div className="breadcrumb-container">
        <div
          className={`breadcrumb-item ${!selectedCourseId ? 'active' : ''}`}
          onClick={handleBreadcrumbClick}
        >
          Courses
        </div>
        {selectedCourseId && selectedCourse && (
          <>
            <span className="breadcrumb-separator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="breadcrumb-arrow">
                <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
              </svg>
            </span>
            <div className="breadcrumb-item active">
              {selectedCourse[0].courseTitle}
            </div>
          </>
        )}
      </div>

      {selectedCourseId && selectedCourse ? (
        <StudentCourseworkTable
          courseTitle={selectedCourse[0].courseTitle}
          courseWorks={selectedCourse}
        />
      ) : (
        <div className="course-cards-container">
          {sortedCourseIds.map((courseId) => {
            const courseWorks = courses[courseId];
            const submissionPercentage = calculateSubmissionPercentage(courseWorks);
            const averageGrade = calculateAverageGrade(courseWorks);
            const statusClass = determineStatusClass(submissionPercentage, averageGrade);
            const inTimeSubmissionPercentage = calculateSubmissionPercentageInTime(courseWorks);

            return (
              <StudentCourseCard
                key={courseId}
                courseTitle={courseWorks[0].courseTitle}
                teacherName={courseWorks[0].teacherName}
                submissionPercentage={submissionPercentage}
                inTimeSubmissionPercentage={inTimeSubmissionPercentage}
                statusClass={statusClass}
                onClick={() => handleCardClick(courseId)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StudentView;
