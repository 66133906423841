import React, { useState, ChangeEvent, MouseEvent } from 'react';
import './CourseworkCard.css';

interface CourseworkCardProps {
  courseworkTitle: string;
  deadline: string;
  optional: boolean;
  onToggleOptional: () => void;
  onClick: () => void;
}

// Utility function to format deadline to Prague time
const formatToPragueTime = (datetimeString: string): string => {
  const date = new Date(datetimeString);

  // Convert to Prague time using toLocaleString
  return date.toLocaleString('cs-CZ', { 
    timeZone: 'Europe/Prague',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const CourseworkCard: React.FC<CourseworkCardProps> = ({
  courseworkTitle,
  deadline,
  optional,
  onToggleOptional,
  onClick
}) => {
  const [isChecked, setIsChecked] = useState(!optional);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation(); // Prevent click event from bubbling up
    setIsChecked(event.target.checked);
    onToggleOptional();
  };

  const handleCardClick = (event: MouseEvent<HTMLDivElement>) => {
    // Check if the click is inside the checkbox container
    if ((event.target as HTMLElement).closest('.checkbox-container')) {
      return; // Don't trigger card click if the checkbox container was clicked
    }
    onClick();
  };

  // Format the deadline to Prague time
  const formattedDeadline = formatToPragueTime(deadline);

  return (
    <div className="coursework-card" onClick={handleCardClick}>
      <h2 className="coursework-title">{courseworkTitle}</h2>
      <p className="student-count">Deadline: {formattedDeadline}</p>
      <div className="toggle-container">
        <span className="status-text">Povinné</span>
        <div className="checkbox-container">
          <label className="switch">
            <input 
              type="checkbox" 
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default CourseworkCard;
