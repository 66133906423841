import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import StudentView from './StudentView';
import './ParentView.css';
import { encodeStudentName, decodeStudentName } from './utils'; // Import the encoding functions

interface ParentViewProps {
    data: Record<string, any>; // Assuming the data is a JSON object with student names as keys
}

const ParentView: React.FC<ParentViewProps> = ({ data }) => {
    const [selectedStudent, setSelectedStudent] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const studentCount = Object.keys(data).length; // Get the count of linked students

    useEffect(() => {
        // Check URL parameters and set the correct view based on them
        const searchParams = new URLSearchParams(location.search);
        const encodedStudentName = searchParams.get('student');

        if (encodedStudentName) {
            const decodedName = decodeStudentName(encodedStudentName);
            const studentData = data[decodedName];
            if (studentData) {
                setSelectedStudent(studentData); // Set the student data if found
            }
        } else {
            setSelectedStudent(null); // Reset if no student in URL
        }
    }, [location.search, data]);

    const handleStudentClick = (studentName: string, studentData: any) => {
        const encodedName = encodeStudentName(studentName);
        navigate(`?student=${encodedName}`); // Update URL with encoded student name
        setSelectedStudent(studentData); // Set the selected student's data
    };

    const handleBreadcrumbClick = () => {
        navigate('', { replace: true }); // Clear the URL parameter and replace the current history entry
        setSelectedStudent(null); // Reset the selected student
    };

    // If a student is selected or if a valid student is found in the URL, render StudentView
    if (selectedStudent) {
        return (
            <div className="parent-view">
                {/* Conditionally render the "Back to Students" button only if there are multiple students */}
                {studentCount > 1 && (
                    <button onClick={handleBreadcrumbClick} className="back-button">
                        Zpět na seznam studentů
                    </button>
                )}
                <StudentView data={selectedStudent} /> {/* Render StudentView with the selected student's data */}
            </div>
        );
    }

    // Default view: Render the parent view with student selection
    return (
        <div className="parent-view">
            <h2>Přiřazení studenti</h2>
            <div className="student-buttons-container">
                {Object.entries(data).map(([studentName, studentData]) => (
                    <button
                        key={studentName}
                        onClick={() => handleStudentClick(studentName, studentData)}
                        className="student-button"
                    >
                        {studentName}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ParentView;
