import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './TeacherView.css';
import CourseCard from './CourseCard';
import CourseworkCard from './CourseworkCard';
import StudentTable from './StudentTable';

interface Submission {
  courseId: string;
  courseTitle: string;
  courseWorkId: string;
  courseWorkTitle: string;
  grade: string | null;
  gradeCategory: string | null;
  link: string;
  studentEmail: string;
  studentName: string;
  optional: boolean;
  late: boolean;
  status: string;
  deadline: string;
  submissionId: string;
  section: string;
}

interface TeacherViewProps {
  data: Submission[];
}

type CourseworkStatus = { [courseWorkId: string]: boolean };

// Utility functions to group data
const groupByCourseId = (data: Submission[]) => {
  return data.reduce<{ [courseId: string]: Submission[] }>((acc, curr) => {
    if (!acc[curr.courseId]) {
      acc[curr.courseId] = [];
    }
    acc[curr.courseId].push(curr);
    return acc;
  }, {});
};

const groupByCourseWorkId = (data: Submission[]) => {
  return data.reduce<{ [courseWorkId: string]: Submission[] }>((acc, curr) => {
    if (!acc[curr.courseWorkId]) {
      acc[curr.courseWorkId] = [];
    }
    acc[curr.courseWorkId].push(curr);
    return acc;
  }, {});
};

// Functions to handle updates
const updateOptionalStatus = async (courseWorkId: string, optional: boolean) => {
  try {
    const response = await fetch('/api/optionality', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        courseWorkId: courseWorkId,
        optional: String(optional),
      }),
    });
    if (response.ok) {
      const result = await response.json();
      console.log(result.message);
    } else {
      console.error('Failed to update optional status');
    }
  } catch (err) {
    console.error('Error updating optional status:', err);
  }
};

const updateStatus = async (submissionId: string, status: string) => {
  try {
    const response = await fetch('/api/change_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        studentSubmissionId: submissionId,
        submissionStatus: status,
      }),
    });
    if (response.ok) {
      const result = await response.json();
      console.log(result.message);
    } else {
      console.error('Failed to update status');
    }
  } catch (err) {
    console.error('Error updating status:', err);
  }
};

// Main component
const TeacherView: React.FC<TeacherViewProps> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [submissions, setSubmissions] = useState<Submission[]>(data);
  const [courseworkStatus, setCourseworkStatus] = useState<CourseworkStatus>(
    data.reduce((acc, curr) => {
      acc[curr.courseWorkId] = curr.optional;
      return acc;
    }, {} as CourseworkStatus)
  );

  const groupedByCourseId = groupByCourseId(submissions);

  // Parse URL to get the current selected course and coursework IDs
  const searchParams = new URLSearchParams(location.search);
  const selectedCourseId = searchParams.get('courseId');
  const selectedCourseWorkId = searchParams.get('courseWorkId');

  const handleCourseCardClick = (courseId: string) => {
    navigate(`?courseId=${courseId}`);
  };

  const handleBreadcrumbClick = (index: number) => {
    if (index === 0) {
      navigate('');
    } else if (index === 1 && selectedCourseId) {
      navigate(`?courseId=${selectedCourseId}`);
    }
  };

  const handleCourseworkCardClick = (courseWorkId: string) => {
    if (selectedCourseId) {
      navigate(`?courseId=${selectedCourseId}&courseWorkId=${courseWorkId}`);
    }
  };

  const handleOptionalChange = async (courseWorkId: string) => {
    const currentStatus = courseworkStatus[courseWorkId];
    const newStatus = !currentStatus;

    setCourseworkStatus((prev) => ({ ...prev, [courseWorkId]: newStatus }));

    try {
      await updateOptionalStatus(courseWorkId, newStatus);

      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission.courseWorkId === courseWorkId
            ? { ...submission, optional: newStatus }
            : submission
        )
      );
    } catch (err) {
      console.error('Error updating optional status:', err);
      setCourseworkStatus((prev) => ({ ...prev, [courseWorkId]: currentStatus }));
    }
  };

  const getStudentsForCoursework = (courseWorkId: string) => {
    return submissions
      .filter((submission) => submission.courseWorkId === courseWorkId)
      .map(({ studentName, grade, submissionId, link, status }) => ({
        studentName,
        grade,
        submissionId,
        link,
        status,
      }));
  };

  const handleUpdateStatus = async (submissionId: string, status: string) => {
    try {
      await updateStatus(submissionId, status);

      // Update local state after successful status update
      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission.submissionId === submissionId
            ? { ...submission, status }
            : submission
        )
      );
    } catch (err) {
      console.error('Error updating status:', err);
    }
  };

  // Ensure selected course and coursework exist before rendering
  const courseTitle = selectedCourseId && groupedByCourseId[selectedCourseId]
    ? groupedByCourseId[selectedCourseId][0].courseTitle
    : '';
  const courseWorkTitle = selectedCourseWorkId && selectedCourseId && groupedByCourseId[selectedCourseId]
    ? groupByCourseWorkId(groupedByCourseId[selectedCourseId])[selectedCourseWorkId]?.[0]?.courseWorkTitle
    : '';

  return (
    <div className="teacher-view-container">
      <div className="breadcrumb-container">
        <div
          className={`breadcrumb-item ${!selectedCourseId && !selectedCourseWorkId ? 'active' : ''}`}
          onClick={() => handleBreadcrumbClick(0)}
        >
          Classrooms
        </div>
        {selectedCourseId && groupedByCourseId[selectedCourseId] && (
          <>
            <span className="breadcrumb-separator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="breadcrumb-arrow">
                <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
              </svg>
            </span>
            <div
              className={`breadcrumb-item ${!selectedCourseWorkId ? 'active' : ''}`}
              onClick={() => handleBreadcrumbClick(1)}
            >
              {courseTitle}
            </div>
          </>
        )}
        {selectedCourseWorkId && courseWorkTitle && (
          <>
            <span className="breadcrumb-separator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="breadcrumb-arrow">
                <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
              </svg>
            </span>
            <div className="breadcrumb-item">
              {courseWorkTitle}
            </div>
          </>
        )}
      </div>
      {selectedCourseId && groupedByCourseId[selectedCourseId] ? (
        <div className="course-details-view">
          {selectedCourseWorkId ? (
            <div className="coursework-details-view">
              <h2>{courseWorkTitle}</h2>
              <StudentTable 
                students={getStudentsForCoursework(selectedCourseWorkId)} 
                onUpdateStatus={handleUpdateStatus}
              />
            </div>
          ) : (
            <div className="coursework-container">
              {Object.keys(groupByCourseWorkId(groupedByCourseId[selectedCourseId]))
                .sort((a, b) => new Date(groupByCourseWorkId(groupedByCourseId[selectedCourseId])[b][0].deadline).getTime() -
                  new Date(groupByCourseWorkId(groupedByCourseId[selectedCourseId])[a][0].deadline).getTime())
                .map(courseWorkId => (
                  <CourseworkCard
                    key={courseWorkId}
                    courseworkTitle={groupByCourseWorkId(groupedByCourseId[selectedCourseId])[courseWorkId][0].courseWorkTitle}
                    deadline={groupByCourseWorkId(groupedByCourseId[selectedCourseId])[courseWorkId][0].deadline}
                    optional={courseworkStatus[courseWorkId]}
                    onToggleOptional={() => handleOptionalChange(courseWorkId)}
                    onClick={() => handleCourseworkCardClick(courseWorkId)}
                  />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="course-wrapper-container">
          {Object.keys(groupedByCourseId)
            .sort((a, b) => groupedByCourseId[a][0].courseTitle.localeCompare(groupedByCourseId[b][0].courseTitle))
            .map(courseId => (
              <div key={courseId} className="course-wrapper">
                <CourseCard
                  courseTitle={groupedByCourseId[courseId][0].courseTitle}
                  section={groupedByCourseId[courseId][0].section}
                  onClick={() => handleCourseCardClick(courseId)}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default TeacherView;
