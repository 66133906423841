// components/Footer.tsx
import React from 'react';
import './Footer.css';

interface FooterProps {
    lastUpdate: string | null;
}

const Footer: React.FC<FooterProps> = ({ lastUpdate }) => {
    return (
        <footer className="footer bg-light py-3 mt-auto">
            <div className="container d-flex justify-content-between align-items-center">
                <div className="text-muted copyright">
                    © 2024 Gymnázium Kudykampus 
                </div>
                
                {lastUpdate && (
                    <div className="text-muted">
                        Last update: {lastUpdate}
                    </div>
                )}
            </div>
        </footer>
    );
};

export default Footer;
